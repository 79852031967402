import axios from "axios";

export const API_URL = process.env.REACT_APP_API_URL

export const API_STORAGE = process.env.REACT_APP_API_STORAGE



const API_ROUTES={
    REFRESH: `${API_URL}/refresh`,
    VERIFY_EMAIL: `${API_URL}/email/verify`,
    SEND_EMAIL_VERIFICATION: `${API_URL}/sendEmailVerification`,
    SEND_EMAIL_RESET_PASSWORD: `${API_URL}/sendEmailResetPassword`,
    RESET_PASSWORD: `${API_URL}/resetPassword`,
    LOGIN: `${API_URL}/login`,
    LOGOUT: `${API_URL}/logout`,
    REGISTER: `${API_URL}/register`,
    CURRENT_USER: `${API_URL}/currentuser`,
    USERS: `${API_URL}/users`,
    THEMES: `${API_URL}/themes`,
    ACCOUNTS: `${API_URL}/accounts`,
    SHARE: `${API_URL}/share`,
    UNSHARE: `${API_URL}/unshare`,
    SHARED: `${API_URL}/shared`,
    ARCHIVE: `${API_URL}/archive`,
    UNARCHIVE: `${API_URL}/unarchive`,
    TRANSACTIONS: `${API_URL}/transactions`,
    CHECK: `${API_URL}/checktransactions`,
    UNCHECK: `${API_URL}/unchecktransactions`,
    TRANSACTION_TYPES: `${API_URL}/transactiontypes`,
    CATEGORIES: `${API_URL}/categories`,
    SUBCATEGORIES: `${API_URL}/subcategories`,
    CURRENCIES: `${API_URL}/currencies`,
    PAYMENT_PLAN: `${API_URL}/plans`,
    LOGOS: `${API_URL}/logos`,
    TICKETS: `${API_URL}/tickets`,
    CONTACTS: `${API_URL}/contacts`,
}

export const API_FUNCTIONS={
    // REFRESH TOKEN
    refresh_token: async(refresh_token)=>{
        try{
            return await axios.post(API_ROUTES.REFRESH, refresh_token)
        }catch(e){
            if(e.response){
                return e.response
            }else{
                throw e
            }
        }
    },
    // VERIFY EMAIL
    verify_email: async(signedUrl) => {
        try{
            return await axios.get(signedUrl)
        }catch(e){
            if (e.response) {
                return e.response
            } else {
                throw e
            }
        }
    },
    sendEmailVerification: async(request, token) => {
        try{
            return await axios.post(API_ROUTES.SEND_EMAIL_VERIFICATION, request, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
        }catch(e){
            return e 
        }
    },
    sendEmailResetPassword: async(data) => {
        try{
            return await axios.post(API_ROUTES.SEND_EMAIL_RESET_PASSWORD, data)
        }catch(e){
            if (e.response) {
                return e.response
            } else {
                throw e
            }
        }
    },
    resetPassword: async(signedUrl, token, data) => {
        try{
            return await axios.post(signedUrl, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            if(e.response){
                return e.response
            }else{
                throw e
            }
        }
    },
    // USERS
    login: async(data) => {
        try{
            return await axios.post(API_ROUTES.LOGIN, data, {
                withCredentials: true
            })
        }catch(e){
            if(e.response){
                return e.response
            }else{
                throw e
            }
        }
    },
    currentUser: async()=>{
        try{
            const response = await axios.get(API_ROUTES.CURRENT_USER, {
                withCredentials: true
                })
            return response
        }catch(e){
            return e
        }

    },
    logout: async()=>{
        try{
            const response = await axios.post(API_ROUTES.LOGOUT,null, {
                withCredentials: true,
            });
            if(response){
                return response
            }
        }catch(e){
            console.error(e)
        }
    },
    allUsers: async(token)=>{
        try{
            const response = await axios.get(API_ROUTES.USERS, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if(response)return response.data
        }catch(e){
            return e
        }
    },
    register: async(data)=>{
        try{
            return await axios.post(API_ROUTES.REGISTER, data)
        }catch(e){
            console.error(e)
        }
    },

    //THEMES
    allThemes: async()=>{
        try{
            const response = await axios.get(API_ROUTES.THEMES)
            return response
        }catch(e){
            return e
        }
    },
    getTheme: async(id) => {
        try{
            const response = await axios.get(API_ROUTES.THEMES+'/'+id)
            return response
        }catch(e){
            return e
        }
    },
    // Email Contacts
    sendMessage: async($request) => {
        try {
            return await axios.post(API_ROUTES.CONTACTS, $request)
        }catch(e){
            return e
        }
    }

}