import React, {useContext, useEffect} from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useLocation, Link } from 'react-router-dom'

import { GlobalContext } from '../contexts/GlobalContext'

import { API_FUNCTIONS } from '../utils/apiFunctions'
import { TOASTS } from '../utils/toasts'
import { autoScroll } from '../utils/functions'
import { Helmet } from 'react-helmet'

function Register() {
    const navigate = useNavigate()
    const {register, handleSubmit,watch ,formState: {errors}} = useForm()

    const createUser = async(data) => {
        try{
            const response = await API_FUNCTIONS.register(data)
            if(response.data?.code === 201){
                TOASTS.success('Votre compte a bien été créé')
                navigate('/connexion')
            }else if(response.data?.code === 422){
                TOASTS.error('problème de validation')
            }else{
                TOASTS.error('Oups, une erreur est survenue...')
            }
        }catch(e){
            console.error(e)
            TOASTS.error('Oups, une erreur serveur est survenue...')
        }
    }
    useEffect(() => {
        autoScroll()
    }, [])
    const password = watch('password', '');
    const acceptConditions = watch('acceptConditions')
  return (
    <div className='mainContainer register'>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Créer un compte - Cloudy Pocket</title>
        </Helmet>
        {/* <h1>Créer un compte</h1> */}

        {/* <form id="registerForm" className='registerForm' onSubmit={handleSubmit(createUser)}>
            <div className="registerForm__bloc">
                <input type="text" id='registerFirstname' {...register('firstname', {required: 'Vous devez entrer votre prénom'})} placeholder=''/>
                <label for="registerFirstname" >Prénom</label>
            </div>
            {errors.firstname && <span className='danger registerForm__error'>{errors.firstname.message}</span>}

            <div className="registerForm__bloc">
                <input type="text" id='registerLastname' {...register('lastname', {required: "Vous devez entrer votre nom"})} placeholder='' />
                <label for="registerLastname">Nom</label>
            </div>
            {errors.lastname && <span className='danger registerForm__error'>{errors.lastname.message}</span>}

            <div className="registerForm__bloc">
                <input type="email" id='registerEmail' autoComplete='off' {...register('email', {required:"L'e-mail est obligatoire"})} placeholder='' />
                <label for='registerEmail'>email</label>
            </div>
            {errors.email && <span className='danger registerForm__error'>{errors.email.message}</span>}

            <div className="registerForm__bloc">
                <input type="password" id='registerPassword' autoComplete='off'{...register('password', {required: "Le mot de passe est obligatoire"})} placeholder='' />
                <label for="registerPassword">Mot de passe</label>
            </div>
            {errors.password && <span className='danger registerForm__error'>{errors.password.message}</span>}

            <div className='registerForm__bloc'>

            {password !== "" && <input type="password" id='confirmPassword' {...register('confirmPassword', { 
                required: "Veuillez confirmer votre mot de passe",
                validate: value => value === password || "Les mots de passe ne correspondent pas"
            })} placeholder='' />}
            {password !== '' && <label htmlFor="confirmPassword">Confirmez le mot de passe</label>}
            </div>
            {errors.confirmPassword &&password !== '' && <span className='danger registerForm__error'>{errors.confirmPassword.message}</span>}

            <div className='registerForm__conditions'>
                <label>J'accepte les <Link to='/legal'>conditions générales d'utilisation</Link></label>
                <input type='checkbox' {...register('acceptConditions')}/>
            </div>


            {acceptConditions ? 
                    <button type='submit'>Créer mon compte</button> 
                : 
                    <span onClick={() => TOASTS.error("Vous devez accepter les conditions d'utilisation pour pouvoir vous inscrire")}>
                        <button className='registerForm__disabledButton' disabled>Créer mon compte</button>
                    </span>
            }
        </form> */}
        <div>
            <h1>✨ L'application arrive bientôt ! ✨</h1>
            <p>Encore un peu de patience, la <strong>sortie officielle</strong> de <strong>Cloudy Pocket</strong> approche !</p>
        </div>

    </div>
  )
}

export default Register
