import React, {useContext, useEffect, useState} from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import { GlobalContext } from '../contexts/GlobalContext'
import { API_FUNCTIONS } from '../utils/apiFunctions'
import { autoScroll } from '../utils/functions'
import { Helmet } from 'react-helmet'
import Spinner from '../components/Spinner'

function VerifyEmail() {
  useEffect(() => {
      autoScroll()
    }, [])
    
  const navigate = useNavigate()
  const location = useLocation()

  const {user} = useContext(GlobalContext)


  const queryParams = new URLSearchParams(location.search);
  const signedUrl = queryParams.get('signed_url');
  const urlToken = queryParams.get('token')
  const userEmail = queryParams.get('userEmail')

  
  const initialTimeout =120

  const [status, setStatus] = useState('')
  const [timeout, setTimeout] = useState(initialTimeout)
  const [timeoutProgress, setTimeoutProgress] = useState(100)
  const [emailIsAlreadyVerified, setEmailIsAlreadyVerified] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const radius = 70
  const circumference = 2 * Math.PI * radius
  
  useEffect(() => {
    if(!signedUrl && !user.email){
      navigate('/')
    }else if(user && user.email_verified_at){
      setEmailIsAlreadyVerified(true)
    }

  }, [user])
  

  useEffect(() => {      
    if(signedUrl){
      
      setStatus('verifying')
      
    }else{
      setStatus('waitingEmail')
    }
  }, [signedUrl])

  const resendEmailVerification = async() => {
    setStatus('waitingEmail')
    setErrorMessage('')
    setTimeout(initialTimeout)
    setTimeoutProgress(120)
    try{
      const request = {
        email: user.email ? user.email : userEmail,
      }
      await API_FUNCTIONS.sendEmailVerification(request, urlToken !== null ? urlToken : localStorage.getItem('tmpToken'))
      
    }catch(e){
      setStatus('error')
      setErrorMessage("Une érreur inconnue est survenue lors de l'envoie de l'email de vérification")
    }
  }


  const verifyEmail = async() => {
    
    try{
      const response = await API_FUNCTIONS.verify_email(signedUrl)
      
      if(response.status === 200){
        localStorage.removeItem('tmpToken')
        setStatus('verified')
      }else if(response.status === 403){
        setStatus('error')
        setErrorMessage('Le lien de vérification est invalide.')
      }else if(response.status === 409){
        setStatus('waitingEmail')
        setEmailIsAlreadyVerified(true)
      }else{
        setStatus('error')
        setErrorMessage('Une erreur inattendue est survenue.')
      }
    }catch(e){
      setErrorMessage('Une erreur inattendue est survenue.')
      setStatus('error')
    }
  }

  useEffect(() => {
    if(status === 'verifying'){
      verifyEmail()
    }
  }, [status])


  useEffect(() => {
    const timer = setInterval(() => {
      if (timeout > 0) {
        setTimeout(timeout - 1)
        setTimeoutProgress(((timeout - 1) / initialTimeout) * 100)
      } else {
        clearInterval(timer)
      }
    }, 1000);

    return () => clearInterval(timer)
  }, [timeout]);

  const strokeDashoffset = circumference + (timeoutProgress / 100) * circumference;


  return (
    <div className='mainContainer login'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vérification de l'Email - Cloudy Pocket</title>
      </Helmet>

      {
        status === 'waitingEmail' &&
          (emailIsAlreadyVerified ? 
              <div className='verifyEmail__content'>
                <h1>Adresse mail déjà vérifiée</h1>
                <p>Votre adresse mail a déjà été vérifiée, il est inutile de la vérifier à nouveau.</p>
                <Link to={'/connexion'} className='btn btn--dark'>Retour à la page de connexion</Link>
              </div>        
            :
              <div className='verifyEmail__content'>
                <h1>Vérifiez votre boîte mail</h1>
                <p>
                  Un email de vérification vous a été envoyé. Pensez à vérifier vos spams si vous ne le recevez pas.
                </p>
                <div></div>
                <div className="verifyEmail__content__circle">
                  <svg width="150" height="150">
                    <circle
                      className="verifyEmail__content__circle__background"
                      cx="75"
                      cy="75"
                      r={radius}
                      fill="transparent"
                    />
                    <circle
                      className="verifyEmail__content__circle__progress"
                      cx="75"
                      cy="75"
                      r={radius}
                      fill="transparent"
                      strokeDasharray={circumference}
                      strokeDashoffset={strokeDashoffset}
                    />
                  </svg>
                  {timeout === 0 &&
                      <div onClick={() => resendEmailVerification()} className='verifyEmail__content__circle__content verifyEmail__content__circle__content--resend'>
                        Renvoyer 
                      </div>
                  }
                  {timeout > 0 &&
                      <div className='verifyEmail__content__circle__content verifyEmail__content__circle__content--wait'>
                        <span>{timeout}s</span>
                      </div>
                  }
                </div>
              </div>)
      }

      {
        status === 'verifying' &&
        <div className='verifyEmail__content'>
          <h1>Vérification en cours</h1>
          <p>Merci de patienter quelques instants pendant que nous validons votre adresse email.</p>
          <Spinner/>
        </div>
      }
      {
        status === 'verified' && 
        <div className='verifyEmail__content'>
          <h1>Vérification terminée</h1>
          <p>
            Votre adresse mail a bien été vérifiée, vous pouvez maintenant vous connecter.
          </p>
          <Link to={'/connexion'} className='btn btn--dark'>Me connecter</Link>
        </div>
      }
      {
        status === 'error' && 
        <div className='verifyEmail__content'>
          <h1>Une erreur est survenue</h1>
          <p>Échec de la vérification de l'adresse email.</p>
          <p>{errorMessage}</p>
          <div onClick={() => resendEmailVerification()} className='btn btn--dark'>
            Renvoyer un email de vérification
          </div>
        </div>
      }
      
      
    </div>
  )
}

export default VerifyEmail