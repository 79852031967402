import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import { autoScroll } from '../utils/functions'


function Conditions() {
  useEffect(() => {
      autoScroll()
    }, [])

  return (
    <div className='mainContainer legal'>
      <h1>Conditions générales</h1>

      <article>
        <h2>Politique de confidentialité</h2>
        <div>
          <h3>Collecte des Informations Personnelles</h3>
        
          <p>Nous collectons les informations personnelles suivantes lors de l’inscription et de l’utilisation de notre application : </p>

          <ul>
            <li>Nom et prénom</li>
            <li>Adresse e-mail</li>
            <li>Mot de passe (crypté en base de données)</li>
          </ul>
    
          <h3>Utilisation des Données</h3>
    
          <p>Les utilisateurs ont un accès total à leurs données et peuvent les modifier à tout moment. Voici comment nous utilisons ces informations :</p>

          <ul>
            <li><strong>Gestion du compte utilisateur</strong> : Les utilisateurs peuvent modifier leurs informations personnelles.</li>
            <li><strong>Suppression du compte</strong> : La suppression du compte utilisateur entraîne la suppression de toutes les données associées.</li>
          </ul>

          <h3>Données Financières</h3>
          
          <p>Pendant l’utilisation de l’application, les utilisateurs peuvent :</p>
    
          <ul>
            <li><strong>Uploader des tickets de caisse</strong></li>
            <li><strong>Créer des comptes bancaires</strong> (à l’image de leurs comptes réels) en renseignant :
              <ul>
                <li>Le nom du compte</li>
                <li>La devise utilisée</li>
                <li>Le solde initial</li>
                <li>Le nom de la banque (facultatif)</li>
                <li>L’adresse de la banque (facultatif)</li>
                <li>L’IBAN (facultatif)</li>
                <li>Le BIC (facultatif)</li>
              </ul>
            </li>
            <li><strong>Créer des transactions bancaires</strong> (débits, crédits, transferts et échéanciers) pour reproduire leurs transactions réelles.</li>
          </ul>

          <h3>Sécurité</h3>
        
          <p>Nous prenons des mesures pour protéger vos données personnelles, y compris le hachage des mots de passe et le cryptage de toutes les données bancaires.</p>
        
        </div>
      </article>

      <article className='legal__mentions'>
        <h2>Mentions légales</h2>
        <div>
            <h3>Informations</h3>
            
            <div>
                <p><strong>Responsable</strong> : Yann Ghistelinck</p>
                <p><strong>Adresse</strong> : 17 rue du Docteur Repin</p>
                <p><strong>Ville</strong> : 72240, Conlie</p>
                <p><strong>Email</strong> : <Link to="/contact">contact@cloudy-pocket.fr</Link></p>
            </div>

            <h3>Hébergeur</h3>

            <div>
                <p><strong>Nom</strong> : O2switch</p>
                <p><strong>Adresse</strong> : Chemin des Pardiaux</p>
                <p><strong>Ville</strong> : 63000, Clermont Ferrand</p>
                <p><strong>Email</strong> : support@o2switch.fr</p>
                <p><strong>Support</strong> : <a href='https://www.o2switch.fr/support-hebergeur/' target="blank">https://www.o2switch.fr/support-hebergeur/</a></p>
            </div>
            
        </div>
      </article>

    </div>
  )
}

export default Conditions