import React,  {useEffect, useState} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import './assets/sass/main.sass'
import {GlobalContext} from './contexts/GlobalContext'
import AxiosProvider from "./utils/AxiosProvider";
import { API_FUNCTIONS } from "./utils/apiFunctions";
import { setDefaultMode, setColorTheme, isTokenExpired } from "./utils/functions";
import useAuthToken from "./hooks/useAuthToken";

// PAGES
import Home from './pages/Home'
import Error from './pages/Error'
import Login from './pages/Login'
import Register from './pages/Register'
import Contact from "./pages/Contact";
import Conditions from './pages/Conditions'
import VerifyEmail from "./pages/VerifyEmail";

// COMPONENTS
import Header from './components/Header'
import Footer from './components/Footer'

import { ToastContainer } from "react-toastify";
import ForgottenPassword from "./pages/ForgottenPassword";
import ResetPassword from "./pages/ResetPassword";



function App() {  
  // useAuthToken()
  
  setDefaultMode()

  const [isLoaded, setIsLoaded] = useState(false)
  const [isLogged, setIsLogged] = useState(localStorage.getItem('isLogged') || false)
  const [token, setToken] = useState(localStorage.getItem('token') || "")
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refresh_token') || "")
  const [user, setUser] = useState(false)
  const [theme, setTheme] = useState(localStorage.getItem('colorTheme') || null)
  const [mode, setMode] = useState(document.body.className)
  const [ windowWidth, setWindowWidth ] = useState(window.innerWidth)
  const [ mobileLimit, setMobileLimit ] = useState(Number(getComputedStyle(document.documentElement).getPropertyValue('--mobile-size')))
  const [ tabletLimit, setTabletLimit ] = useState(Number(getComputedStyle(document.documentElement).getPropertyValue('--tablet-size')))


  const checkCurrentUser = async()=>{
    try{
      const response = await API_FUNCTIONS.currentUser()        
      if(response.data){
        setIsLogged(true)
        localStorage.setItem('isLogged', true)
        setUser(response.data.data)
      }else{
        localStorage.removeItem('isLogged')
        setIsLogged(false)
        // TOASTS.error('Vous êtes déconnecté')
      }
    }catch(e){
      localStorage.removeItem('isLogged')
      setIsLogged(false)
      console.error(e)
      // TOASTS.error('Vous êtes déconnecté')
    }
  }

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    if(!isLoaded && isLogged){
      checkCurrentUser()
    }
    setIsLoaded(true)
  }, [isLoaded])

  useEffect(() => {
    if(theme){
      setColorTheme(theme)
    }
  }, [theme])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])



  

  return (
    <GlobalContext.Provider value={{token, setToken, refreshToken, setRefreshToken, user, setUser, theme, setTheme, windowWidth, mobileLimit, tabletLimit, mode, setMode, isLogged, setIsLogged}}>
      {/* <AxiosProvider> */}

      <ToastContainer
        position="bottom-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme= {localStorage.getItem('mode') === 'dark' ? 'light' : 'dark'}
        // transition: Bounce,
      />
        <Router>
          <Header/>

          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/connexion" element={<Login/>} />
            <Route path="/nouveau_compte" element={<Register/>} />
            <Route path='/contact' element={<Contact/>}/>
            <Route path='/legal' element={<Conditions/>} />
            <Route path='/verify_email' element={<VerifyEmail/>} />
            <Route path="/forgot_password" element={<ForgottenPassword/>}/>
            <Route path="/reset_password" element={<ResetPassword/>} />
            <Route path="*" element={<Error/>} />
          </Routes>

          <Footer/>
        </Router>
      {/* </AxiosProvider> */}
    </GlobalContext.Provider>
  )
}

export default App;
